import React, { useState } from 'react';
import { InstantSearch, SearchBox, useHits, UseHitsProps } from 'react-instantsearch';
import { ContainerWrapper } from '~/dashboard/layout/container';
import { useAuth } from '~/hooks/useAuth';
import Spinner from '~/components/spinner';
import MultiSelectFilter from '~/dashboard/search/multiselectFilter';
import SearchPagination from '~/dashboard/search/searchPagination';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import truncate from '~/utils/truncate';
import ExecutiveThemeComparison from '~/dashboard/overview/components/executiveThemeComparison';

const RankingRow = (props: UseHitsProps): JSX.Element => {
    const { hits, results } = useHits(props);
    if (!hits || typeof results?.nbHits != 'undefined') {
        return (
            <>
                {hits.map((hit: any) => {
                    if (hit.active) {
                        return <ResultRow hit={hit} key={hit.objectID || hit.id} />;
                    } else {
                        return null;
                    }
                })}
            </>
        );
    } else {
        return <Spinner />;
    }
};

const ResultRow = ({ hit }: { hit: any }): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div
            className="flex flex-col w-full border-b border-gray-100 py-3 px-6 bg-white"
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="flex flex-row w-full cursor-pointer" key={hit.objectID || hit.id}>
                <div className="w-[40%]">
                    <div className="flex flex-row">
                        <img
                            src={hit.profile_thumbnail_url}
                            alt="Executive Thumbnail"
                            className="bg-white rounded-xl w-full max-w-[50px] max-h-[50px] object-cover aspect-square mr-1"
                        />
                        <a
                            href={`/app/executives/${hit.slug}`}
                            className="font-semibold text-lg text-black hover:text-blue-500"
                        >
                            {hit.name}
                            <p className="text-sm text-gray-500 hover:text-blue-500">
                                {truncate(hit.title, 50, '...')}
                            </p>
                        </a>
                    </div>
                </div>
                <div className="w-[30%]">
                    <div className="flex flex-row mb-2">
                        <img
                            src={hit.company.logo_url}
                            alt="Company Logo Thumbnail"
                            className="bg-white rounded-xl w-full max-w-[25px] max-h-[25px] object-contain mr-1"
                        />
                        {hit.company.name}
                    </div>
                </div>
                <div className="w-[20%]">
                    {hit?.jaccard?.rank && hit?.jaccard?.rank !== 0 ? (
                        <TooltipProvider delayDuration={100}>
                            <Tooltip>
                                <TooltipTrigger className="text-black rounded px-2 py-1 text-sm bg-cometrics-100">
                                    <i className="far fa-chart-line text-cometrics mr-2"></i>
                                    {hit.jaccard.rank}/10
                                </TooltipTrigger>
                                <TooltipContent className="w-96 border-cometrics">
                                    <p>
                                        We use a Jaccardian index to calculate how aligned Executive communications are
                                        with Corporate communications.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ) : null}
                </div>
                <div className="w-[10%]">
                    <p>{hit?.['verified_content_count']}</p>
                </div>
                <div className="w-[10%]">
                    <p>{hit?.['verified_linkedin_content_count']}</p>
                </div>
            </div>
            {isOpen ? (
                <div className="pt-5">
                    <ExecutiveThemeComparison executiveId={hit.uuid} noPadding />
                </div>
            ) : null}
        </div>
    );
};

const SearchAndFilter = (): JSX.Element => {
    const { meili, meiliClient } = useAuth();
    return (
        <ContainerWrapper className="px-5 background-offset pb-10">
            {meili && meiliClient ? (
                <InstantSearch
                    indexName={`${meili.indexes['executive']}:jaccard.display:desc`}
                    searchClient={meiliClient}
                    routing={true}
                >
                    <div className="container flex flex-col mx-auto mt-10">
                        <header className="flex flex-col lg:flex-row lg:items-center justify-between mb-10">
                            <h1 className="text-3xl font-semibold text-black font-primary">Executives</h1>
                            <div className="flex flex-col lg:flex-row lg:space-x-3">
                                <MultiSelectFilter
                                    attribute="company.sector.name"
                                    placeholderText="Filter by Sector"
                                    limit={10000}
                                    operator="or"
                                    className="min-w-[250px]"
                                    sortBy={['name', 'count:desc']}
                                />
                                <MultiSelectFilter
                                    attribute="company.name"
                                    placeholderText="Filter by Company"
                                    limit={10000}
                                    operator="or"
                                    className="min-w-[250px]"
                                    sortBy={['name', 'count:desc']}
                                />
                                <SearchBox />
                            </div>
                        </header>
                        <div className="flex flex-col">
                            <div className="bg-gray-50 flex flex-row w-full rounded-t-lg py-3 px-6">
                                <div className="w-[40%]">
                                    <h2 className="text-base font-bold font-primary text-black text-left">Executive</h2>
                                </div>
                                <div className="w-[30%]">
                                    <h2 className="text-base font-bold font-primary text-black text-left">Company</h2>
                                </div>
                                <div className="w-[20%]">
                                    <h2 className="text-base font-bold font-primary text-black text-left">Alignment</h2>
                                </div>
                                <div className="w-[10%]">
                                    <h2 className="text-base font-bold font-primary text-black text-left">Content</h2>
                                </div>
                                <div className="w-[10%]">
                                    <h2 className="text-base font-bold font-primary text-black text-left">
                                        Linkedin Posts
                                    </h2>
                                </div>
                            </div>
                            <RankingRow />
                        </div>

                        <SearchPagination />
                    </div>
                </InstantSearch>
            ) : (
                <Spinner />
            )}
        </ContainerWrapper>
    );
};

export default SearchAndFilter;
