import React from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '~/utils/ui';

const SearchNavBar = (): JSX.Element => {
    return (
        <section className="flex flex-row border-b border-gray-200 px-5">
            <section className="flex flex-row container">
                <NavLink
                    to={`/app/executives`}
                    className={({ isActive }) =>
                        cn(
                            'mr-4 text-black hover:text-verdant py-2',
                            isActive && 'font-heavy border-b-4 border-cometrics',
                        )
                    }
                    end
                >
                    Search
                </NavLink>
                <NavLink
                    to={`/app/executives/ranking`}
                    className={({ isActive }) =>
                        cn(
                            'mr-4 text-black hover:text-verdant py-2',
                            isActive && 'font-heavy border-b-4 border-cometrics',
                        )
                    }
                >
                    Rankings
                </NavLink>
            </section>
        </section>
    );
};

export default SearchNavBar;
