import React, { Dispatch, SetStateAction } from 'react';
import stringToClassName from '~/utils/stringToClassName';
import { accentByParentThemeLight } from '~/components/themeAccents';

const ThemeComparisonList = ({
    themes,
    hoverTheme,
    setHoverTheme,
}: {
    themes: any[];
    hoverTheme: string;
    setHoverTheme: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
    return (
        <>
            {themes.map((theme: any, index: number) => {
                const themeCssClass = stringToClassName(theme.themeName);
                const accentColor = accentByParentThemeLight(theme.parentThemeName);
                return (
                    <div
                        key={theme.themeName}
                        className={`flex flex-row align-top ${themeCssClass} `}
                        onMouseEnter={() => setHoverTheme(themeCssClass)}
                        onMouseLeave={() => setHoverTheme('')}
                    >
                        <h3 className="font-light py-1">{index + 1}.</h3>
                        <h3
                            className={`px-2 py-1 ${
                                themeCssClass === hoverTheme ? 'bg-' + accentColor : ''
                            } rounded-xl`}
                        >
                            {theme.themeName}
                        </h3>
                    </div>
                );
            })}
        </>
    );
};

export default ThemeComparisonList;
