import React from 'react';
import Ranking from '../components/table/ranking';
import SearchNavBar from '../components/searchNavBar';

const ExecutiveRanking = (): JSX.Element => {
    return (
        <>
            <SearchNavBar />
            <Ranking />
        </>
    );
};

export default ExecutiveRanking;
